@use '../variables';
@use '../breakpoints';

.subscription-list {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 3rem 0 0;
  list-style: none;
  flex: 1;

  a {
    color: inherit;
  }

  a:hover {
    text-decoration: none;
    color: inherit;
  }

  &--tiers {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
}

.subscription-item {
  flex: 1;

  &--tier:not(&--selected):not(&--active) {
    // flex-basis: 24%;
    border: 2px solid variables.$mercury;
    background: none;
    color: variables.$mineshaft;
  }

  &--tier.subscription-item--recommended,
  &--active {
    flex-basis: 28%;
    margin-top: -20px;
    margin-bottom: 0;
    border-color: #7c7c7c;

    @include breakpoints.breakpoint(mobileonly) {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    &:before {
      display: block;
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 3px 5px;
      border-radius: 5px;
      background-color: #5d5d5d;
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &--active {
    &:before {
      content: 'Subscription Active';
    }
  }

  &--tier.subscription-item--recommended:not(&--active) {
    &:before {
      content: 'recommended';
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
  }

  &__status {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    margin-top: 1rem;
    font-weight: 400;
  }

  &__billing {
    margin-top: 10px;
    text-align: right;
  }

  &__cost {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -1px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;

    &-from {
      font-size: 50%;
      margin-right: 0.3em;
    }

    &_per_user {
      margin: -15px 0 0px 0;
      font-size: 1.2em;
      font-weight: 500;
    }
  }

  &__outstanding {
    color: red;
  }

  &__term {
    font-size: 0.8rem;
    letter-spacing: 2px;
    text-transform: uppercase;

    &-strong {
      font-weight: 600;
    }
  }

  &__reference {
    margin-bottom: -1.2em;
  }

  &__select {
    margin-top: 20px;
    font-size: 16px;
    background-color: #5d5d5d;
    border: 1px solid #7c7c7c;
    border-radius: 0.25rem;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    max-width: 100%;
    padding: 1rem 2.4rem;
    margin: 1em 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__info {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__label {
    &-unpaid {
      &:after {
        content: 'Payment Due';
        display: block;
        position: absolute;
        top: 8%;
        right: -12%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #faa339;
        text-transform: uppercase;
        background: lighten(#faa339, 32%);
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
    &-payment_error {
      &:after {
        content: 'Payment Error';
        display: block;
        position: absolute;
        top: 10%;
        right: -13%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #f15d59;
        text-transform: uppercase;
        background: lighten(#f15d59, 30%);
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
    &-awaiting_fullfilment {
      &:after {
        content: 'Activation Pending';
        display: block;
        position: absolute;
        top: 12%;
        right: -14%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #6cb0b0;
        text-transform: uppercase;
        background: #afeeee;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
    &-lapsed {
      &:after {
        content: 'Lapsed';
        display: block;
        position: absolute;
        top: 5%;
        right: -9%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #f15d59;
        text-transform: uppercase;
        background: lighten(#f15d59, 30%);
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
    &-renewal_due {
      &:after {
        content: 'Expired';
        display: block;
        position: absolute;
        top: 8%;
        right: -12%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #faa339;
        text-transform: uppercase;
        background: lighten(#faa339, 32%);
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
    &-active {
      &:after {
        content: 'Active';
        display: block;
        position: absolute;
        top: 5%;
        right: -9%;
        z-index: 10;
        padding: 0.5rem 3rem;
        font-size: 90%;
        font-weight: 600;
        color: #4bb543;
        text-transform: uppercase;
        background: lighten(#4bb543, 40%);
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        -webkit-box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
        box-shadow: 0 9px 10px -10px rgb(0 0 0 / 20%);
      }
    }
  }
}

.subscription-expiry {
  height: 100%;
  max-width: 160px;
  padding: 0.4rem 0.8rem;
  border-radius: 0.5rem;
  line-height: 1;
  font-size: 70%;
  text-align: right;
  text-transform: uppercase;

  &--valid {
    color: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.6);
  }

  &--invalid {
    color: variables.$mineshaft;
    background-color: #dc8733;
    border: 2px solid #dc8733;
  }
}
