@use './breakpoints';
@use './variables';

.form-control {
  label {
    display: block;
  }
  input {
    display: block;
    padding: 0.8rem 1rem;
    border-radius: 4px;
    box-sizing: border-box !important;
  }
}

.form-row {
  margin-bottom: 1rem;

  > div {
    align-items: flex-end;
  }
}

.form-half {
  flex: 0 0 48.99998%;
  max-width: 48.99998%;
  padding: 0 1.5rem 0 0;
}

label.form-label {
  font-weight: 700;
}

.select,
.ajs-input,
.form-control-wrapper input,
.form-control-wrapper textarea {
  position: relative;
  width: 100%;
  padding: 1rem;
  font-family: Roboto, sans-serif;
  font-size: 100%;
  line-height: 1.5;

  border-radius: 0.5rem;
  box-sizing: border-box !important;
}

.select,
.ajs-input,
.form-light input,
.form-light textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #333;
  &::placeholder {
    color: #eee;
  }
}

// .select,
// .ajs-input,
.form-dark input,
.form-dark textarea {
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  &::placeholder {
    color: #aaa;
  }
}

.form-control-wrapper.disabled {
  position: relative;
  &:after {
    content: '\f023';
    font-family: 'Font Awesome 5 Free';
    position: absolute;
    right: 2rem;
    top: 1rem;
    color: #cccccc;
    font-size: 1rem;
  }

  input {
    background-color: #f6f6f6;
    color: #b3b3b3;
    border-color: #dddddd;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.form-light.disabled {
  &:after {
    color: #cccccc;
  }

  input {
    background-color: #f6f6f6;
    color: #b3b3b3;
    border-color: #dddddd;
  }
}

.form-dark.disabled {
  &:after {
    color: #a6a6a6;
  }

  input {
    background-color: #3d3d3d;
    color: #a6a6a6;
    border-color: #555555;
  }
}

// .search {
//   position: relative;
//   width: 40%;

//   label {
//     position: absolute;
//     left: 1rem;
//     top: 0.8rem;
//   }

//   input {
//     background: #424242;
//     border: 0px;
//     padding: 0.8rem 1rem 0.8rem 3rem;
//     color: white;
//     border-radius: 4px;
//     width: 100%;
//     max-width: 300px;
//     box-sizing: border-box;
//     transition: width 0.2s ease-out 0.2s;

//     &::placeholder {
//       color: lighten(#424242, 20%);
//     }

//     &:focus {
//       width: 100%;
//       border-radius: 4px;
//       padding: 0.8rem 1rem 0.8rem 3rem;
//       outline: none;
//     }

//     &:focus::placeholder {
//       color: lighten(#424242, 20%);
//     }
//   }
// }

fieldset {
  background: #fafafa;
  padding: 1.5rem;
  border: 1px solid #f2f2f2;
}

legend {
  background-color: #b80b09;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.authorising-position {
  padding: 0 0.5rem 0.5rem 0.5rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  legend {
    background-color: transparent;
    color: #182026;
    font-weight: 700;
    border: 1px solid #ddd;
  }
  .form-row {
    margin: 1rem;
  }
}

.form {
  &-check,
  &-radio {
    display: flex;
    padding: 0.8em 0 0.8em 0;

    label {
      position: absolute;
    }
  }
}

.form-text-input-checked-container {
  align-items: center;
}

.input-checkbox {
  display: block;
  right: 1.5em;
}
