.popover-wrapper {
  position: relative;
  // display: inline-block;

  &--element-disabled {
    & > *:first-child {
      pointer-events: none;
    }
  }
}

.popover-container {
  display: none;
  box-sizing: border-box;
  font-size: 0.9em;
  padding: 0.9em;
  position: absolute;
  min-width: min-content;
  max-width: max-content;
  z-index: 999;
  font-weight: 400;
  border-radius: 3px;
  &--no-padding {
    padding: 0;
  }
  &--is-open {
    display: block;
    opacity: 0;
  }
  &--is-translated {
    animation: popup 0.4s forwards;
    // opacity: 1;
  }
  &--light {
    background: #ffffff;
    color: #333;
    box-shadow: 1px 1px 3px #777;
  }
  &--dark {
    background: #333;
    color: #ffffff;
    box-shadow: 1px 1px 3px #777;
  }

  // &--positioned {
  //   &-top {
  //     transform: translate(-50px, -80px);
  //   }
  //   &-top-right {
  //     transform: translate(50px, -80px);
  //   }
  //   &-right {
  //     transform: translate(50px, -30px);
  //   }
  //   &-bottom-right {
  //     transform: translate(50px, 20px);
  //   }
  //   &-bottom {
  //     transform: translate(-50px, 20px);
  //   }
  //   &-bottom-left {
  //     transform: translate(-150px, 20px);
  //   }
  //   &-left {
  //     transform: translate(-150px, -30px);
  //   }
  //   &-top-left {
  //     translate: (-150px, -80px);
  //   }
  // }
}

@keyframes popup {
  25% {
    left: 0px;
    opacity: 0.3;
  }
  50% {
    left: 10px;
    opacity: 0.5;
  }
  75% {
    left: -10px;
    opacity: 0.7;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}
