@use '../variables';

.site-footer {
  background-color: variables.$rradar-black;
  color: variables.$rradar-white;
  padding: 2em;
  font-size: 0.9em;

  p {
    display: contents;
  }

  p,
  .site-footer--column-a {
    margin: 0;
    height: 27px;
  }

  .site-footer--column-a {
    display: flex;
    align-items: center;
  }

  hr {
    width: 100%;
    margin: 0;
  }

  h4 {
    letter-spacing: 2px;
    margin-bottom: 1em;
    &:after {
      content: '';
      display: block;
      bottom: -8px;
      left: 0;
      width: 30px;
      height: 2px;
      background: #c00;
      margin-top: 0rem;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    display: flex;
    align-items: baseline;

    li {
      position: relative;
      margin-right: 1em;
      a {
        text-decoration: none;
        color: variables.$rradar-white;
        &:hover {
          color: darken(variables.$rradar-white, 20);
        }
      }
    }
  }

  &--column {
    margin: 1em 0 2em 0;
    @media (min-width: 320px) {
      margin: 1em 2em 2em 0;
    }
    @media (min-width: 1024px) {
      margin: 1em 5em 2em 0;
    }

    &-header {
      text-transform: uppercase;
    }
  }
  &--endline {
    margin-top: 1em;
  }
  &--logo {
    // position: absolute;
    display: flex;
    flex: 1;
    justify-content: end;
    &-item {
      height: 45px;
      @media (min-width: 370px) {
        height: 65px;
      }
    }
  }
}
