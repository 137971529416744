@use "../variables";
@use "../breakpoints";

.error-page {
  height: 80vh;
  display: flex;
  align-items: center;

  &--dark {
    background: #333;
    color: variables.$rradar-white;
  }
  &--light {
    background: variables.$rradar-white;
    color: #333;
  }

  &--contents {
    justify-content: space-around;
    align-items: center;
    height: 45vh;
    &-logo {
      svg {
        height: 45vw;
        width: 45vw;
        @include breakpoints.breakpoint(tablet) {
          height: 30vw;
          width: 30vw;
        }
        @include breakpoints.breakpoint(laptop) {
          height: 15vw;
          width: 15vw;
        }
      }
    }
    &-text {
      text-align: center;
      &-status {
        h1 {
          margin: 0;
          &:after {
            margin: 0;
            width: auto;
          }
        }
      }

      &-message {
        h3 {
          margin: 0;
          &:after {
            display: none;
          }
        }
      }
      &-button {
        margin-top: 1em;
        &--dark {
          a {
            text-decoration: none;
            color: white;

            &:active {
              color: darken(white, 20);
            }
          }
        }
        &--light {
          a {
            text-decoration: none;
            color: black;

            &:active {
              color: lighten(black, 20);
            }
          }
        }
      }
    }
  }
}
