@use '../mixins';

.mobile-menu {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  width: 18rem;
  height: 100vh;
  font-size: 1rem;
  background-color: rgb(51, 51, 51);
  transform: translateX(27.5rem);
  overflow: initial;
  color: white;

  &--isOpen {
    transform: translateX(0px);
    transition: all 0.2s ease 0s;
  }

  &-container {
    height: inherit;
  }

  &-header {
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      background-color: rgb(51, 51, 51);
      border: none;
      color: white;
      padding: 1em;
      font-size: 30px;
      cursor: pointer;
      outline: none;
    }
  }

  nav {
    border-top: 1px solid rgb(69, 69, 69);
  }
  &-body {
    height: 90%;
    justify-content: space-between;
    overflow-y: auto;
    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      display: flex;

      li {
        position: relative;
        border-bottom: 1px solid rgb(69, 69, 69);
        text-decoration: none;

        &:hover {
          background-color: #999;
        }
      }
      a,
      .menu-item {
        cursor: pointer;
        display: block;
        padding: 1em;
        height: 100%;
        box-sizing: border-box;
        text-decoration: none;
        color: white;
      }
    }
    &--profile {
      padding: 2em;
      ul {
        padding: 0.3em;
        li {
          border-bottom: none;
        }
      }
      a {
        color: #333 !important;
      }
    }
  }

  .profileitem {
    margin-bottom: 1rem;
    flex-flow: column-reverse;
  }
}

.new:after {
  content: 'NEW';
  background-color: #cc0000;
  @include mixins.after-navigation-style;
}

.beta:after {
  content: 'BETA';
  background-color: #4b6eaf;
  @include mixins.after-navigation-style;
}

.active {
  color: darken(#868686, 10%);
  box-shadow: inset 0px -5px 0px 0px;
}
