@use "./variables";

$select-dropdown-bg: #fff;
$select-input-border: darkgrey;
$select-item-highlighted: #f4f4f4;
$border-radius: 0.5rem;
$select-focus-border: #b7b7b7;

.select,
.tag-control {
  &.disabled,
  &.disabled input {
    cursor: not-allowed;
    opacity: 0.5;
    user-select: none;
  }
}

.select--multi input,
.tag-control input {
  display: inline-block;
  max-width: 100%;
  padding: 0.4rem 0 0.4rem 0.2rem;
  margin-bottom: 0.1rem;
  font-size: 1rem;
  vertical-align: baseline;
  background-color: #f9f9f9;
  border: 0;
  border-radius: 0;

  &:focus {
    outline: none;
    box-shadow: 0 2px 0 #57c2f0;
  }

  &.hidden-text,
  &.hidden-text::placeholder {
    color: transparent;
    box-shadow: none;
  }
}

.select {
  .placeholder {
    color: #666666;
  }

  &--container {
    position: relative;
  }

  &--clear {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 28px;
    background: #b8b8b8;
    color: white;
    border-radius: 11px;
    text-align: center;
    font-size: 1.2rem;
    line-height: 0;
    padding: 0.7rem 1rem;
  }

  &--single {
    .select__dropdown {
      top: 100%;
      right: -1px;
      left: -1px;
      width: auto;
    }
  }

  &--multi {
    .select__dropdown {
      top: 0;
    }
  }

  .selected {
    font-size: 1rem;
    font-weight: 400;
    color: #333333;
    &.placeholder {
      font-size: 1rem;
      opacity: 0.5;
    }
  }

  &--is-open {
    border-color: $select-focus-border;
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    + .select__list {
      border-color: $select-focus-border;
    }
  }

  &__dropdown {
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    border: 1px solid $select-focus-border;
    border-bottom-right-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    box-sizing: border-box;

    input {
      display: block;
      width: 100%;
      padding: 10px;
      margin: 0;
      background-color: #fff;
      border: 0;
      border-bottom: 1px solid $select-input-border;
      font-weight: 300;
      max-width: 100%;
      font-size: 14px;
      border-radius: 0;
      &:focus {
        outline: none;
      }
    }

    &--list {
      max-height: 300px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      will-change: scroll-position;
      word-break: break-all;

      .selectable-item,
      > div {
        position: relative;

        &:hover {
          cursor: pointer;
          background: $select-item-highlighted;

          &:after {
            content: 'Press to select';
            position: absolute;
            right: 1rem;
            font-size: 1rem;
            opacity: 0.5;
          }
        }
        padding: 1rem;
        font-size: 1rem;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
          user-select: none;
        }
      }

      .selectable-item--sublabel {
        color: #b7b7b7;
        font-size: 1rem;
        margin-left: 1rem;
        &:before {
          content: '(';
        }

        &:after {
          content: ')';
        }
      }

      .selectable-item--disabled {
        cursor: not-allowed !important;
        background-color: #f3f0f0;
      }
    }
  }
}
