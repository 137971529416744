@use '../breakpoints';
@use '../mixins';

.navigation-bar {
  border-bottom: 2px solid #f4f4f4;
  background: #fcfcfc;
  color: #868686;
  letter-spacing: 0.2px;
  display: none;
  @include breakpoints.breakpoint(laptop) {
    display: block;
  }
  // position: sticky;
  // position: -webkit-sticky;
  z-index: 99;
  &-container {
    .new:after {
      content: 'NEW';
      background-color: #cc0000;
      @include mixins.after-navigation-style;
    }

    .beta:after {
      content: 'BETA';
      background-color: #4b6eaf;
      @include mixins.after-navigation-style;
    }

    .active {
      color: darken(#868686, 10%);
      box-shadow: inset 0px -5px 0px 0px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      display: flex;

      li {
        position: relative;
      }
    }

    > ul li {
      cursor: pointer;
      box-shadow: inset 0px 0px 0px 0px;
      transition: all 0.2s ease-out;

      :first-child {
        padding: 1.8rem 1.8rem;
      }

      a {
        display: inline-block;
        text-decoration: none;
        color: #868686;
      }

      &:link,
      &:visited {
        color: inherit;
      }

      &:hover {
        color: darken(#868686, 10%);
        box-shadow: inset 0px -5px 0px 0px;
      }
    }
    // .menu-item {
    //   &:first-child {
    //     padding: 1.8rem 1.8rem;
    //   }
    // }
  }
}
