@use "../variables";
@use "../mixins";

.red-button {
  @include mixins.button(variables.$rradar-red, variables.$rradar-white);
}

.primary-button {
  @include mixins.button(variables.$colour-primary-green, variables.$rradar-white);
}

.secondary-button {
  @include mixins.button(
    variables.$rradar-white,
    variables.$colour-dark-grey,
    variables.$colour-dark-grey,
    variables.$rradar-charcoal,
    variables.$rradar-white
  );
}

.cancel-button {
  @include mixins.button(
    variables.$rradar-white,
    variables.$colour-dark-grey,
    variables.$rradar-white,
    variables.$rradar-charcoal,
    variables.$rradar-white,
    variables.$rradar-charcoal
  );
}

.white-button {
  @include mixins.button(
    transparent,
    variables.$rradar-white,
    variables.$rradar-white,
    variables.$rradar-white,
    variables.$rradar-red,
    variables.$rradar-white,
    1px
  );
  box-shadow: inset 0px -4px 0px 0px rgba(255, 255, 255, 0.4);
  transition: all 0.2s ease-in-out;
}

.button-area-buttons {
  & > * {
    margin-right: 0.7em;
  }

  & > *:last-child {
    margin-right: 0;
  }
  justify-content: flex-end;
}
