.site-loader {
  min-height: 80vh;
  display: flex;

  &--loader {
    position: absolute;
    top: -3em;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #fff;
    opacity: 90%;
    // color: #333;
    svg {
      transform: scale(0.3);
    }

    span {
      padding: 1em;
    }
    &-dark {
      color: white;
      background-color: #333;
      // opacity: 50%;
    }
  }
  &--text {
    box-shadow: inset 0px 12px 1px -9px #cc0000;
    position: absolute;
    margin-top: 50vh;
    align-items: baseline;
    padding: 1em;

    .elipses-loading {
      width: 1.2em;
      margin-left: 0.4em;
      text-align: left;
      &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(10, end) 1500ms infinite;
        animation: ellipsis steps(10, end) 1500ms infinite;
        content: '\2026'; /* ascii code for the ellipsis character */
        width: 0px;
        font-size: 2em;
      }
    }
  }
  &--content {
    &--hidden {
      opacity: 10%;
    }
    width: 100%;
  }
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
