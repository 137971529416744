@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 100em) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 64em) {
      @content;
    }
  } @else if $point == tabletplus {
    @media (min-width: 50em) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 50em) and (max-width: 63.99em) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 37.5em) and (max-width: 49.99em) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  }
}
