@use "../variables";

.core-banner {
  width: 90%;
  &--warning {
    border: 0.2em solid variables.$rradar-accent-orange;
    background-color: variables.$rradar-accent-orange;
  }
  &--info {
    border: 0.2em solid variables.$rradar-accent-turquoise;
    background-color: variables.$rradar-accent-turquoise;
  }
  &--success {
    border: 0.2em solid variables.$colour-primary-green;
    background-color: variables.$colour-primary-green;
  }
  &--notice {
    border: 0.2em solid variables.$rradar-accent-blue;
    background-color: variables.$rradar-accent-blue;
  }
  &--error {
    border: 0.2em solid variables.$rradar-accent-red;
    background-color: variables.$rradar-accent-red;
  }
  &--hidden {
    display: none;
  }
  &-icon {
    align-items: center;
    justify-content: center;
    min-width: 5em;
    svg {
      transform: scale(2.5);
      color: white;
    }
  }
  &-content {
    width: 100%;
    padding: 0.2em 0.2em 0.4em 1em;
    font-weight: 300;
    letter-spacing: 0.8px;
    background-color: variables.$rradar-white;
    color: #333333;
    &--warning {
      // color: variables.$rradar-accent-orange;
      // background-color: lighten(variables.$rradar-accent-orange, 35%);
    }
    &--info {
      // color: variables.$rradar-accent-turquoise;
      // background-color: lighten(variables.$rradar-accent-turquoise, 45%);
    }
    &--success {
      // color: variables.$colour-primary-green;
      // background-color: lighten(variables.$colour-primary-green, 60%);
    }
    &--notice {
      // color: variables.$rradar-accent-blue;
      // background-color: lighten(variables.$rradar-accent-blue, 32.5%);
    }
    &--error {
      // color: variables.$rradar-accent-red;
      // background-color: lighten(variables.$rradar-accent-red, 30%);
    }
  }
  &-heading {
    justify-content: space-between;
    margin-bottom: 0.2em;
    &--title {
      font-size: 1.75em;
    }
    &--close {
      cursor: pointer;
      svg {
        transform: scale(1.25);
        margin: 0 0.35em 0 0;
      }
    }
  }
}
