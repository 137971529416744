.logo-text-path,
.logo-circle {
  // transition: fill, 0.1s ease-in;
}

.logo-svg .logo-text-path {
  animation-name: text;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.5s;
}

.logo-svg .logo-circle {
  animation-name: circle;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0.5s;
}

@keyframes circle {
  0% {
    fill: #b80a0a;
  }
  50% {
    fill: #fff;
  }
  100% {
    fill: #b80a0a;
  }
}

@keyframes text {
  0% {
    fill: #fff;
  }
  50% {
    fill: #333;
  }
  100% {
    fill: #fff;
  }
}
