@use '../breakpoints';

.modal {
  &-header {
    padding: 1vh 0 1vh 0;
    justify-content: space-between;
  }
  &-content {
    min-height: 41vh;
    padding: 1vh 0 1vh 0;
  }
  &-buttons {
    display: flex;
    justify-content: flex-end;
  }

  :global(.ReactModal__Content) {
    padding: 0px !important;
  }

  :global(.ReactModal__Content--after-open) {
    padding: 0px;
    z-index: 1000 !important;
    @include breakpoints.breakpoint(laptop) {
      inset: 40px 25% 40px 25% !important;
    }
  }
  &--dark {
    :global(.ReactModal__Content--after-open) {
      z-index: 1000 !important;
      padding: 0px;
      background-color: #333333 !important;
      color: white;
    }
  }
  &--wide {
    :global(.ReactModal__Content--after-open) {
      inset: 15vh 15vw 15vh 15vw !important;
    }
  }
}

.core-modal {
  padding: 40px;
}

:global(.ReactModal__Overlay--after-open) {
  z-index: 1000 !important;
}
