.tooltip {
  position: relative;
  display: inline-block;
  width: fit-content;
  text-align: left;
}

.tooltip:before {
  content: attr(data-text);
  position: absolute;
  box-sizing: border-box;
  width: 200px;
  padding: 10px;
  border-radius: 3px;
  background: #555;
  color: #fff;
  text-align: center;
  font-weight: 400;

  display: none;
}

.tooltip.right:before {
  top: 50%;
  left: 100%;
  margin-left: 20px;
  transform: translate(0, -50%);
}

.tooltip.left:before {
  top: 50%;
  right: 100%;
  margin-right: 20px;
  transform: translate(0, -50%);
}

.tooltip.bottom:before {
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip.top:before {
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip:after {
  content: '';
  position: absolute;
  border: 10px solid #555;
  display: none;
}

.tooltip.right:after {
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  border-color: transparent #555 transparent transparent;
}

.tooltip.left:after {
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  border-color: transparent transparent transparent #555;
}

.tooltip.top:after {
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-color: #555 transparent transparent transparent;
}
.tooltip.bottom:after {
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  border-color: transparent transparent #555 transparent;
}

.tooltip:hover:before,
.tooltip:hover:after {
  display: block;
}
