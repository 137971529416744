@use "../variables";

.search-container {
  width: 30vw;
  padding: 0.5em;
  justify-content: space-around;
  align-items: center;

  input {
    position: relative;
    display: block;
    background: none;
    border: none;
    height: 2em;
    width: 90%;
    outline: none;
    font-size: 1.2em;
  }

  &--dark {
    background: #333;
    color: #ccc;

    input {
      color: #fff;
    }
  }
  &--light {
    background: variables.$rradar-pearl;
    color: #333;
  }
}
