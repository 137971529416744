.heading--1::after,
.heading--2::after {
  content: '';
  display: block;
  background: #c00;
  height: 3px;
  border-radius: 3px;
  width: 150px;
  margin-top: 1rem;
}
