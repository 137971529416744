@use '../variables';

.core-slider {
  &--horizontal-slider {
    width: 100%;
    // max-width: 500px;
    height: 2em;
    padding: auto;
    margin: 1em 0em 1em 0em;
  }
  &--thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 5px solid #cc0000;
    border-radius: 100%;
    display: block;
    // box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    width: 1em;
    height: 1em;
    text-align: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    outline-style: none;
    font-weight: 0;
  }
  &--track {
    position: relative;
    background: #ddd;
    height: 5px;
    top: 10px;
  }
  &--track-0 {
    background-color: #cc0000;
  }
  &--mark {
    // height: 0.5em;
    // width: 0.5em;
    // background: #ffffff;
    // border: 5px solid black;
    // border-radius: 100%;
    // top: 5px;

    &-label {
      position: relative;
      top: 25px;
      left: 8px;
    }
  }
}

// .track.track-0 {
//   background: '#cc000';
//   height: 5px;
//   top: 10px;
// }

// .track.track-1 {
//   background: #ddd;
// }
