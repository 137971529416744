@use '../breakpoints';

.site-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 100;
  .branded-header {
    background: linear-gradient(-297deg, #242424, #333 50%, #2d2d2d 0, #2d2d2d);
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    &-container {
      align-items: center;
    }
    &--main {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      &-logo {
        svg {
          width: 70px;
          height: 70px;

          @include breakpoints.breakpoint(mobileonly) {
            width: 50px;
            height: 50px;
          }
        }
      }
      &-search {
        margin-left: 6vw;
        display: none;

        @include breakpoints.breakpoint(tabletplus) {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
        }
      }
    }
    &--profile {
      display: none;

      @include breakpoints.breakpoint(laptop) {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }

      &-image {
        border-radius: 50%;
        margin-left: 1rem;
      }
    }
  }
}
