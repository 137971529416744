.logo-text-path,
.logo-circle {
  transition: fill, 0.1s ease-in;
}

.logo-svg:hover .logo-text-path {
  fill: #333;
}

.logo-svg:hover .logo-circle {
  fill: #fff;
}
