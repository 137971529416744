.tag {
  display: inline-block;
  padding: 4px 10px;
  margin-right: 4px;
  margin-bottom: 1px;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  word-break: break-all;
  vertical-align: middle;
  background-color: #333333;
  border: 1px solid #222222;
  border-radius: 20px;

  &--selected {
    opacity: 0.75;
  }

  a {
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    display: inline-block;
    width: 14px;
    padding: 0;
    margin-left: 0.6rem;
    color: hsla(0, 0%, 100%, 0.75);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    outline-width: 0;
  }
}
