ul.breadcrumbs {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 80%;

  > li:after {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 90%;
  }

  > li:last-of-type:after {
    display: none;
  }

  > li:first-of-type > a {
    padding-left: 0;
  }
}

ul.breadcrumbs a {
  padding: 0.5rem;
}
