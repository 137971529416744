@use '../breakpoints';

.profileitem {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;

  &-drop-down {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  &_slot {
    display: flex;
    margin-left: 3rem;
    font-size: 100%;
    line-height: 1.2;
    color: #666;
    flex-direction: column;

    @include breakpoints.breakpoint(laptop) {
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
    }

    // &--company {
    // }

    // &--user {
    //   margin-left: 1rem;
    // }

    &--image {
      border-radius: 50%;
      max-width: 30px;
      height: 30px;
      margin-right: 1rem;
    }

    &--image-custom {
      border-radius: 50%;
      max-width: 30px;
      height: 30px;
      margin-right: 1rem;
      transform: scale(1.55, 1.45);
      -ms-transform: scale(1.55, 1.45);
      -webkit-transform: scale(1.55, 1.45);
    }

    &--icon {
      // margin-right: 1rem;
      font-size: 1.6rem;
      width: 30px;
      height: 30px;
    }

    &--header {
      // display: block;
      font-weight: 500;
      margin: 0 0.3em 0 0;
      // padding: 5px;

      &--white {
        color: #ffffff;
      }
      &--grey {
        color: #666;
      }
      &--black {
        color: #333;
      }
    }
  }

  &--icon_margin {
    margin: 0 0.5rem 0 0;
  }

  &--header {
  }
}

.slot_header {
  &--name {
  }

  &--interaction {
    display: inline-block;
    margin-left: 0.2rem;
    font-size: 80%;
    cursor: pointer;
  }
}
