@use '../breakpoints';
@use '../variables';

.tabs {
  display: flex;
  flex-flow: column nowrap;
  @include breakpoints.breakpoint(laptop) {
    display: flex;
    flex-flow: row wrap;
  }

  &--panel {
    width: 100%;

    @include breakpoints.breakpoint(tablet) {
      width: 50%;
    }

    @include breakpoints.breakpoint(laptop) {
      width: 75%;
    }

    fieldset {
      width: 100%;
    }
  }
  &-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: variables.$rradar-white;
    min-width: 20%;
    margin-bottom: 2rem;

    @include breakpoints.breakpoint(tablet) {
      width: 25%;
    }

    @include breakpoints.breakpoint(laptop) {
      margin-bottom: 0;
      margin-right: 2rem;
    }

    a {
      position: relative;
      color: variables.$rradar-white;
      padding: 1rem 1rem;
      background-color: variables.$mineshaft;
      width: 100%;
      display: inline-block;
      box-sizing: border-box !important;

      &[aria-selected='true'],
      &:hover {
        color: variables.$rradar-white !important;
        background-color: lighten(variables.$mineshaft, 10%);
      }

      &[aria-selected='true']:after {
        position: absolute;
        content: '\f061';
        right: 1rem;
        font-family: 'Font Awesome 5 Free';
        color: variables.$rradar-white;
      }
    }
  }
}
