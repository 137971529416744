@use './mixins';
@use './breakpoints';
@use './variables';

.flex {
  display: flex;
  &-row {
    @include mixins.flex(row);
    &-wrap {
      @include mixins.flex(row, wrap);
      &-reverse {
        @include mixins.flex(row, wrap-reverse);
      }
    }
  }
  &-column {
    @include mixins.flex(column);
    &-wrap {
      @include mixins.flex(column, wrap);
      &-reverse {
        @include mixins.flex(column, wrap-reverse);
      }
    }
  }
}

.required {
  &::before {
    content: '*';
    color: #c00;
    position: relative;
    margin-left: 0rem;
    line-height: 0;

    font-size: 1.2rem;
    margin-right: 0.6rem;
  }
}

.relative {
  position: relative;
}

.isPointer {
  cursor: pointer;
}

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 0 1vw;
}

.site-body {
  min-height: 80vh;

  &-content {
    padding: 3vw 4vw;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.section {
  &--introduction {
    @include breakpoints.breakpoint(mobileonly) {
      padding: 2rem 0 5rem 0;
    }
    padding: 5rem 0;
  }

  &--darkgrey {
    color: #ffffff;
    background: transparent;
  }
}

.arrow-button {
  text-decoration: none;
  color: #989898;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: #777777;
  }
}

.helper-span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 80%;
  margin: 1rem 0;
  cursor: help;
  text-decoration: underline;
}

.grid {
  display: grid;
  display: -ms-grid;

  &--column {
    grid-gap: 1rem;
    &-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.vertical-title {
  @include breakpoints.breakpoint(mobileonly) {
    margin-bottom: 1rem;
    flex: 1;
    margin-right: 0rem;
    align-items: flex-start;
    padding: 2rem;
  }

  background-color: #cc0000;
  margin-bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem 4rem;
  color: white;
  margin-right: 1rem;
  background: rgb(204, 0, 0);
  background: linear-gradient(125deg, rgba(204, 0, 0, 1) 0%, rgb(131, 0, 0) 100%);

  &:after {
    background-color: #ffffff;
  }
}

//Wants removing eventually

.checkbox-horizontal--container {
  display: block;
  .checkbox-container {
    display: inline-block;
    margin-right: 1.2rem;

    .checkbox:checked + label,
    .checkbox:not(:checked) + label {
      padding-left: 2.75rem;
    }

    .checkbox:checked + label:after,
    .checkbox:not(:checked) + label:after {
      top: -5%;
      left: 5%;
    }
  }
}
/* The switch - the box around the slider */
.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 24px;
  margin: 5px;
}
/* Hide default HTML checkbox */
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
/* The slider */
.slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: #cccccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  content: '';
  position: absolute;
  bottom: 5px;
  left: 4px;
  width: 15px;
  height: 15px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #b80a0a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #b80a0a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tile-item {
  position: relative;
  flex: 0 1 0px;
  min-width: 320px;
  max-width: 320px;
  min-height: 380px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  list-style-type: none;
  box-sizing: border-box !important;

  &-grey {
    border: 2px solid variables.$mineshaft;
    background-color: variables.$mineshaft;
    color: #ffffff;
  }

  &-white {
    border: 2px solid variables.$mercury;
    background: none;
    color: variables.$mineshaft;
  }

  @include breakpoints.breakpoint(laptop) {
    min-width: 400px;
    max-width: 24%;
  }

  @include breakpoints.breakpoint(tablet) {
    min-width: 400px;
    max-width: 400px;
  }

  @include breakpoints.breakpoint(phablet) {
    min-width: 360px;
    max-width: 360px;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.sticky {
  position: sticky;
}

.width {
  &-100 {
    width: 100%;
  }
}
