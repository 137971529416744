@use "./variables";
@use "./mixins";

// *,
// :before,
// :after {
//   box-sizing: content-box !important;
// }

// * {
//   margin: 0%;
// }

html {
  font-family: variables.$font-body;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;

  @include mixins.scrollbar();

  body {
    font-size: variables.$font-size;
    margin: 0;
    // font-size: 10px;
    font-weight: 300;
    color: #333333;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    padding: 0px;
    margin: 0px;
  }
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  font-weight: 300;
  font-family: 'Roboto';
}

p {
  line-height: 1.7;
}

html,
body {
  min-height: 100vh;
}

strong {
  font-weight: 700;
}

figure {
  margin: 0;
}
