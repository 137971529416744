.popover-menu {
  min-width: 280px;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 5px;
  display: flex;
  flex-direction: column;

  &--small {
    min-width: 180px;
  }

  &-item {
    cursor: pointer;
    &--small {
      a {
        padding: 5px;
      }
    }

    a {
      display: block;
      padding: 1em;
      text-decoration: none;
    }
  }

  &-item--light {
    a {
      color: #182026;
    }
    background: #ffffff;
    &:hover {
      background: #efefef;
    }
    &--selected {
      background: #efefef;
      &:hover {
        background: #ffffff;
      }
    }
    &--disabled {
      opacity: 0.8;
      cursor: default;
      pointer-events: none;
    }
  }

  &-item--dark {
    a {
      color: #ffffff;
    }
    background: #333;
    &:hover {
      background: #232323;
    }
    &--selected {
      background: #232323;
      &:hover {
        background: #333333;
      }
    }
    &--disabled {
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
    }
  }
}
