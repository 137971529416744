@use "./variables";

.checkbox {
  &:not(:checked) {
    display: none;

    + label {
      display: flex;
      position: relative;
      height: 22px;
      padding-left: 1.2rem;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        background: #ffffff;
        border: 2px solid #b7b7b7;
        border-radius: 0.3rem;
      }

      &:after {
        content: '\2713';
        position: absolute;
        top: 0;
        left: 15%;
        color: #878787;
      }
    }
  }

  &:checked {
    display: none;

    + label {
      display: flex;
      position: relative;
      height: 22px;
      padding-left: 1.2rem;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.25rem;
        height: 1.25rem;
        background: #4d6eae;
        border: 2px solid #4d6eae;
        border-radius: 0.3em;
      }

      &:after {
        content: '\2713';
        position: absolute;
        top: 10%;
        left: 7px;
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    transform: scale(1.6);
  }
}

.label-span {
  display: inline-block;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 0.9em;
  user-select: none;
  position: relative;
  top: 2px;
}
